:global #root {
  min-height: 100vh; // root should have 100vh minimal height for widgets container to position right
  display: flex; // outlet container will grow to fill the page
  flex-direction: column;
}

/* this container only arrange widgets in position,
   it should not respond to any pointer events. */
.widgets-container {
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
}
