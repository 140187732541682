.container {
  :global(.dark) & :global(.simplebar-scrollbar::before) {
    box-shadow: 0 0 1px #000000;
    background: #eee;
  }

  :global(.simplebar-track) {
    z-index: 0;
  }
}

.hideNativeScrollBar {
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
  }
}
