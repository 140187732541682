.defaultBackground {
  &::before,
  &::after {
    content: " ";
    position: fixed;
    z-index: -1;
    bottom: -524px;
    width: 800px;
    height: 800px;
    opacity: 0.1;
    filter: blur(50px);
    border-radius: 9999px;
  }

  &::before {
    left: -444px;
    background: linear-gradient(180deg, #5441fa 0%, #ec5461 100%);
  }

  &::after {
    right: -444px;
    background: linear-gradient(180deg, #4e4ff5 0%, #3effd1 100%);
  }
}
