.BlueGradientAlert {
  background: linear-gradient(
    90deg,
    rgba(62, 85, 255, 0.3) 0%,
    rgba(16, 36, 80, 0.3) 100%
  );

  @apply text-gray-400;

  a {
    @apply text-blue-600;
  }
}
