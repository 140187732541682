$gradient: linear-gradient(90deg, #ea580c 0%, #0b54ff 100%);

%normalBg {
  background-image: $gradient;
}

%hoverBg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ),
    $gradient;
}

%activeBg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    $gradient;
}

%disableBg {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    $gradient;
}

.mainButtonBg {
  @extend %normalBg;

  &:hover {
    @extend %hoverBg;
  }

  &:active {
    @extend %activeBg;
  }

  &:disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @extend %disableBg;
  }
}
