.RecommendedFarmListItemBackground {
  background: linear-gradient(90deg, #e46f55 0%, #b54ec6 100%);

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      linear-gradient(90deg, #e46f55 0%, #b54ec6 100%);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(90deg, #e46f55 0%, #b54ec6 100%);
  }
}
