.OrderListItemBg {
  cursor: pointer;

  background-image: linear-gradient(
    var(--OrderListItem-bgColor),
    var(--OrderListItem-bgColor)
  );
  background-size: var(--OrderListItem-bgSize);
  background-position: right 0;
  background-repeat: no-repeat, no-repeat;

  &:hover {
    background-color: #1e40af;
  }
}
