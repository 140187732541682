@use "../styles/mixins/gradientBorder";

.tickGradientBorder {
  @include gradientBorder.gradientBorder(
      4px,
      100px,
      linear-gradient(89.81deg, #1d4ed8 0.17%, #7c3aed 99.83%)
  );
}

.handleGradientBorder {
  @include gradientBorder.gradientBorder(
    4px,
    100px,
    linear-gradient(89.81deg, #1d4ed8 0.17%, #7c3aed 99.83%)
  );
}
