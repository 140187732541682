:root {
  --CardModalContent-width: auto;
  --CardModalContent-height: auto;
  --CardModalContent-minHeight: auto;
}

.sizingLimit {
  width: var(--CardModalContent-width);
  height: var(--CardModalContent-height);
  min-height: var(--CardModalContent-minHeight);
  max-width: 100%;
}

@screen lg {
  .sizingLimit {
    max-width: 80vw;
  }
}
