.table {
  table {
    @apply table-auto border-collapse w-full my-4;
  }

  th,
  td {
    @apply border border-gray-600 px-2.5 py-3;
  }

  th {
    @apply bg-gray-800;
  }
}
