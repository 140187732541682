.root {
  background: rgb(17, 24, 39);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(40px);

  @apply m-0 p-6 rounded-2xl;
}

.caption {
  @apply gap-x-5 justify-start pb-6 border-b border-gray-500/30;
}

.caption_label {
  @apply text-xl leading-7 font-medium text-gray-200;
}

.table {
  @apply mt-4;
}

.head_cell {
  @apply text-base leading-6 font-medium text-gray-200;
}

.button {
  width: 3.75rem;
  max-width: 3.75rem;
  height: 2.25rem;
  border-radius: 1.125rem;

  &:hover:not([disabled]) {
    @apply bg-black/20;
  }

  &:focus:not([disabled]) {
    border: none !important;
    @apply bg-black/20;
  }

}

.selected {
  border-radius: 1.125rem;
}

.range_start, .range_end, .range_middle, .selected {
  background-color: #2563eb !important;
}
.range_end {
  border-radius: 0 1.125rem 1.125rem 0;
}
.range_start {
  border-radius: 1.125rem 0 0 1.125rem;
}
.range_middle {
  border-radius: 0;
}