@mixin gradientBorder($borderWidth, $borderRadius, $borderColor) {
  & {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $borderRadius;
    padding: $borderWidth;
    background: $borderColor;
    mask-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
    mask-clip: content-box, border-box;
    mask-composite: exclude;
  }

  &:global(.rounded-full)::before {
    border-radius: 9999px;
  }

  &:global(.rounded-lg)::before {
    border-radius: 0.5em;
  }

}
