.markdown {
  @apply flex flex-col gap-5;

  ol,
  ul {
    @apply flex flex-col gap-5;
    @apply list-decimal list-inside;
  }

  li > p {
    display: inline;
  }

  > img,
  > p > img {
    display: block;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 100%;
  }
}
