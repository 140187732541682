@use "../../../../styles/mixins/gradientBorder";

.buttonBorder {
  @include gradientBorder.gradientBorder(
    2px,
    50px,
    linear-gradient(89.81deg, #ea580c 0.17%, #0b54ff 99.83%)
  );

  &:hover {
    @include gradientBorder.gradientBorder(2px, 50px, white);
  }

  &:active {
    @include gradientBorder.gradientBorder(2px, 50px, #9ca3af);
  }

  &:disabled,
  &[disabled],
  &[aria-disabled="true"] {
    @include gradientBorder.gradientBorder(2px, 50px, #9ca3af);
  }
}
