.html {
  background-image: url("_/bg-galaxy@3x.webp"),
    linear-gradient(
      89.73deg,
      #3a127a 0.33%,
      rgba(18, 40, 122, 0.5) 8.42%,
      rgba(18, 40, 122, 0) 27.8%,
      rgba(18, 40, 122, 0) 76.07%,
      rgba(18, 40, 122, 0.5) 92.87%,
      #3a127a 99.85%
    );
  background-size: 100%, 100%;
  background-position: top center, top center;
  background-repeat: no-repeat, repeat-y;
}
