.defaultBackground {
  position: relative;
  &::after {
    content: " ";
    background-image: url("./assets/BRC-20_background_desktop.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: overlay;
    position: absolute;
    z-index: -1;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
