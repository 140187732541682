.term {
  strong {
    @apply text-yellow-500;
  }

  a {
    @apply text-blue-600;
  }

  p:not(:last-child) {
    margin-bottom: 1em;
  }
}
