@use "../../styles/mixins/gradientBorder";

.indicator {
  @include gradientBorder.gradientBorder(
    var(--loading-indicator-border-width),
    9999px,
    conic-gradient(
      from 180deg at 50% 50%,
      rgba(255, 255, 255, 0) 0deg,
      #ffffff 360deg
    )
  );
}
